.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 2em 0.5em 0.75em;
}

@media screen and (min-width: 640px) {
  .root {
    padding: 3em 1em 1em;
  }
}

footer {
  margin-top: auto;
}
