.chess-match {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.match-state {
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.5rem;
  color: var(--main-color);
}
@media screen and (min-width: 640px) {
  .match-state {
    font-size: 2em;
  }
}

.join-match {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.join-match__text {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  color: var(--main-color);
}
.join-match__btn {
  padding: 1em;
  border: 3px solid var(--secondary-color);
  background: transparent;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--secondary-color);
}

.match-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.5em;
}
.match-buttons div:not(:last-child) {
  margin-right: 1em;
}
.function-btn {
  width: 1.5em;
  opacity: 0.3;
  cursor: pointer;
}
@media screen and (min-width: 640px) {
  .match-buttons div:not(:last-child) {
    margin-right: 2em;
  }
  .function-btn {
    width: 2.5em;
  }
}
