@import './assets/css/normalize.css';
@import './assets/css/base.css';

:root {
  box-sizing: border-box;

  /* Custom Variables */
  --secondary-color: #b41f4a;
  --main-color: #032e83;
  --paragraph-color: #4e6187;

  --tablet-breakpoint: 640px;
  --laptop-breakpoint: 1024px;
  --desktop-breakpoint: 1280px;
}

*,
::before,
::after {
  /* Allow third-party component to set other box-sizing value */
  box-sizing: inherit;
}

body {
  margin: 0;
  background-color: #f5edeb;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

@media screen and (min-width: 640px) {
  :root {
    font-size: 1.125em;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    font-size: 1.25em;
  }
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}
