.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-match-text {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  color: var(--main-color);
}

.create-match-btn {
  padding: 1em;
  border: 3px solid var(--secondary-color);
  background: transparent;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--secondary-color);
}

.match {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.match__url {
  margin-bottom: 2em;
  display: flex;
  color: var(--main-color);
  font-size: 1rem;
}
.match__url input {
  margin-right: 0.25em;
  color: var(--paragraph-color);
}
.match__url button {
  color: var(--paragraph-color);
}
.match__url button img {
  width: 1rem;
}
.match__url button, .match__url input {
  padding: 0.25em;
  border: 3px solid var(--main-color);
  background: transparent;
}
.match__url button:hover {
  color: var(--main-color);
}

.match__waiting {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.match__waiting p {
  font-size: 1rem;
  color: var(--paragraph-color);
}
.match__waiting img {
  width: 7em;
  height: auto;
}
@media screen and (min-width: 640px) {
  .match__waiting img {
    width: 10em;
  }
}
