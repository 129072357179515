.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  color: var(--paragraph-color);
}
@media screen and (min-width: 640px) {
  .footer {
    padding: 0 0.5em;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1280px) {
  .footer {
    padding: 0 3em;
  }
}

.about {
  order: 2;
  text-align: center;
  margin-bottom: 0.5em;
}
.about a {
  font-weight: 600;
  color: inherit;
  text-decoration: none;
}
@media screen and (min-width: 640px) {
  .about {
    margin-bottom: 0;
  }

  .social {
    order: 1;
  }
}
.social__link:not(:last-child) {
  margin-right: 0.5em;
}
.social__icon {
  width: 1.5rem;
}
nav {
  margin-bottom: 1em;
  font-weight: bold;
  color: var(--secondary-color);
  cursor: pointer;
}
@media screen and (min-width: 640px) {
  nav {
    margin-bottom: 0;
  }
}
