.chess-board {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 2em 0 0.75em;
}
@media screen and (min-width: 640px) {
  .chess-board {
    padding: 2.5em 0 1em;
  }
}

.cg-wrap {
  width: calc(100vw - 2rem);
  height: calc(100vw - 2rem);
  position: relative;
  display: block;
}
@media screen and (min-width: 640px) {
  .cg-wrap {
    width: calc(100vw - 3rem);
    height: calc(100vw - 3rem);
    max-width: 680px;
    max-height: 680px;
  }
}

.promotion-overlay {
  width: 100%;
  position: absolute;
  top: 0.25em;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Be just higher than board pieces */
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
}
.promotion-overlay img {
  width: 2.5em;
  cursor: pointer;
}
.promotion-overlay img:not(:last-child) {
  margin-right: 1.5em;
}
@media screen and (min-width: 640px) {
  .promotion-overlay {
    top: 0;
  }
  .promotion-overlay img {
    width: 4em;
  }
  .promotion-overlay img:not(:last-child) {
    margin-right: 4em;
  }
}
